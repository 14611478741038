import React, { createContext, useContext, useState, useEffect } from "react";
import { usePageContent } from "./PageContext";

const TimerContext = createContext();

export const useTimer = () => useContext(TimerContext);

export const TimerProvider = ({ children }) => {
  const { timerTime } = usePageContent();
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (timerTime) {
      setTimer(timerTime * 60);
    }
  }, [timerTime]);

  const [showHideElement, setShowHideElement] = useState(true);

  useEffect(() => {
    if (timer === null) return;

    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          setShowHideElement(false);
          clearInterval(interval);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
    <TimerContext.Provider value={{ timer, showHideElement }}>
      {children}
    </TimerContext.Provider>
  );
};
