import React from "react";

import Payment from "@solidgate/react-sdk";

import { useRemoteConfig } from "../../../context/RemoteConfigContext";
import { useEvent } from "../../../context/EventContext";
import { usePaymentForm } from "../../../context/PaymentFormContext";

import styles from "./PayCardStyle.module.css";

const PayCard = ({ paypalBtnRef, googleContainerRef, appleContainerRef }) => {
  const { analyticsEvent } = useEvent();
  const { paymentButtons } = useRemoteConfig();
  const { merchantDataSet, startOrderStatusCheck } = usePaymentForm();

  const handleOnSuccess = (e) => {
    console.log("handleOnSuccess: ", { e });
    analyticsEvent("addPaymentInfo");

    if (paypalBtnRef.current) {
      paypalBtnRef.current.style.display = "none";
    }
    startOrderStatusCheck();
  };

  const googlePayButtonParams = {
    enabled: paymentButtons.google_pay,
    color: "black",
  };
  const applePayButtonParams = {
    enabled: paymentButtons.apple_pay,
    // integrationType: "js",
    color: "black",
    type: "plain",
  };

  const customFormStyles = {
    template: "flat",
    submit_button: {
      "background-color": "#02BEB2;",
    },
  };
  const formParams = {
    formTypeClass: "flat",
    submitButtonText: "Continue",
  };

  return (
    <div className={styles.cardPayContainer}>
      <div className={styles.textBlock}>
        <div className={styles.hrText} />
        <span className={styles.cardDetailsText}>or pay with card</span>
        <div className={styles.hrText} />
      </div>
      <Payment
        merchantData={merchantDataSet}
        googlePayButtonParams={googlePayButtonParams}
        applePayButtonParams={applePayButtonParams}
        googlePayContainerRef={googleContainerRef}
        applePayContainerRef={appleContainerRef}
        styles={customFormStyles}
        formParams={formParams}
        onSuccess={handleOnSuccess}
      />
    </div>
  );
};

export default PayCard;
