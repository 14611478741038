import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";

import { useEnvContext } from "./EnvironmentContext";

import { useConsoleLog } from "../hooks/consoleLog";

import Loader from "../pages/templates/Loader/Loader";

const DevMode = createContext();

export const useDevMode = () => useContext(DevMode);

const LOCAL_STORAGE_KEY = "MemoryOSDevModeVariant";

export const DevModeProvider = ({ children }) => {
  const { isProduction } = useEnvContext();

  const logger = useConsoleLog();

  const [isDevModeReady, setIsDevModeReady] = useState(false);
  const [devModeVariant, setDevModeVariant] = useState();

  const generateRandomNumber = (max, min) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const initializeDevMode = useCallback(() => {
    if (!isProduction) {
      const storedVariant = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (storedVariant) {
        setDevModeVariant(Number(storedVariant));
      } else {
        const newVariant = generateRandomNumber(2, 1);
        setDevModeVariant(newVariant);
        localStorage.setItem(LOCAL_STORAGE_KEY, newVariant.toString());
      }
    } else {
      setDevModeVariant(null);
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
    setIsDevModeReady(true);
  }, [isProduction]);

  useEffect(() => {
    initializeDevMode();
  }, [initializeDevMode]);

  useEffect(() => {
    if (devModeVariant !== undefined) {
      logger.devMode(`Current devModeVariant: ${devModeVariant}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devModeVariant]);

  const generateNewVariant = useCallback(() => {
    if (!isProduction) {
      const newVariant = generateRandomNumber(2, 1);
      setDevModeVariant(newVariant);
      localStorage.setItem(LOCAL_STORAGE_KEY, newVariant.toString());
      logger.devMode(`New devModeVariant generated: ${newVariant}`);
    } else {
      logger.warn(`Cannot generate new variant in production mode.`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProduction]);

  const STORAGE_KEY = "MemoryOSstagingEvent";

  const getInitialStatus = () => {
    if (isProduction) return false;

    try {
      const storedValue = localStorage.getItem(STORAGE_KEY);
      if (storedValue === null) return false;
      return JSON.parse(storedValue);
    } catch (error) {
      logger.error("Error reading from localStorage:", error);
      return false;
    }
  };

  const [stagingEventsStatus, setStagingEventsStatus] = useState(
    getInitialStatus()
  );

  useEffect(() => {
    try {
      if (isProduction) {
        setStagingEventsStatus(false);
        localStorage.removeItem(STORAGE_KEY);
        return;
      }

      localStorage.setItem(STORAGE_KEY, stagingEventsStatus);
      logger.warnInfo("Staging events status:", stagingEventsStatus);
    } catch (error) {
      logger.critical("Staging event:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProduction, stagingEventsStatus]);

  const contextValue = {
    devModeVariant,
    generateNewVariant,
    stagingEventsStatus,
    setStagingEventsStatus,
  };

  if (!isDevModeReady) {
    return <Loader />;
  }

  return <DevMode.Provider value={contextValue}>{children}</DevMode.Provider>;
};
