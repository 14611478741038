import React, { useEffect, useMemo } from "react";
import ReactDOM from "react-dom/client";

import { APP_VERSION } from "./constants/version";
import { useVersionCheck } from "./hooks/AppVersionCheck/useVersionCheck";

import * as amplitude from "@amplitude/analytics-browser";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";

import { ScrollProvider } from "./context/ScrollContext";
import { FirebaseConfigProvider } from "./context/RemoteConfigContext";
import { EventProvider } from "./context/EventContext";
import { UrlProvider } from "./context/urlContext";
import { ErrorHandlerProvider } from "./context/ErrorHandlerContext";
import { PaymentProvider } from "./context/PaymentBlockContext";
import { ButtonRefProvider } from "./context/ButtonRefContext";
import { EmailValidatorProvider } from "./context/EmailValidatorContext";
import { DeviceProvider } from "./context/DeviceCheckContext";
import { DevModeProvider } from "./context/DevMode";
import { PaymentFormProvider } from "./context/PaymentFormContext";
import { EnvProvider } from "./context/EnvironmentContext";
import { AnalyticsUserDataProvider } from "./context/AnalyticsUserData";
import { AmplitudeProvider } from "./context/AmplitudeContext";
import { RouteHandlerProvider } from "./context/RouteHandlerContext";
import { PageProvider } from "./context/PageContext";
import { PageConfigProvider } from "./context/PageConfigContext";
import { EventFilterProvider } from "./context/EventFilter";
import { GrowthBookABtestProvider } from "./context/GrowthBookContext";
import { CampaignswellProvider } from "./context/CampaignswellContext";
import { UserDataProvider } from "./context/UserData/UserDataContext";

import Loader from "./pages/templates/Loader/Loader";

import "./index.css";

import App from "./App";

function VersionCheckWrapper({ children, version }) {
  const isChecking = useVersionCheck(version);

  if (isChecking) {
    return <Loader />;
  }

  return children;
}

amplitude.init("ee6a72438081ce5583b67e41c270dbd", {
  defaultTracking: true,
  autocapture: {
    attribution: true,
    sessions: true,
    formInteractions: true,
  },
});

const getOrCreateUserId = () => {
  const storedId = localStorage.getItem("MemoryOSabid");
  if (storedId) {
    return storedId;
  }
  const newId = crypto.randomUUID();
  localStorage.setItem("MemoryOSabid", newId);
  return newId;
};

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-42gJQRjfigHm1xSc",
  enableDevMode: true,
  subscribeToChanges: true,
  attributes: {
    id: getOrCreateUserId(),
    deviceId: navigator.userAgent,
  },
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    // console.log("Viewed Experiment", {
    //   experimentId: experiment.key,
    //   variationId: result.key,
    // });
  },
});

const Index = () => {
  const environment = useMemo(() => {
    const currentURL = window.location.href;
    const productionDomain = "start.memoryos.com";
    const stagingDomain = "staging.start.memoryos.com";

    if (
      currentURL.includes(productionDomain) &&
      !currentURL.includes(stagingDomain)
    ) {
      return "production";
    } else if (
      currentURL.includes("localhost") ||
      currentURL.includes("127.0.0.1")
    ) {
      return "development";
    } else {
      return "staging";
    }
  }, []);

  useEffect(() => {
    if (environment === "staging") {
      import("eruda").then((lib) => lib.default.init());
    }
  }, [environment]);

  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <EnvProvider>
        <GrowthBookABtestProvider>
          <RouteHandlerProvider>
            <ErrorHandlerProvider>
              <DeviceProvider>
                <UrlProvider>
                  <DevModeProvider>
                    <FirebaseConfigProvider>
                      <PageConfigProvider>
                        <UserDataProvider>
                          <ScrollProvider>
                            <EventFilterProvider>
                              <CampaignswellProvider>
                                <EventProvider>
                                  <ButtonRefProvider>
                                    <PaymentProvider>
                                      <EmailValidatorProvider>
                                        <PaymentFormProvider>
                                          <AnalyticsUserDataProvider>
                                            <AmplitudeProvider>
                                              <PageProvider>
                                                <App />
                                              </PageProvider>
                                            </AmplitudeProvider>
                                          </AnalyticsUserDataProvider>
                                        </PaymentFormProvider>
                                      </EmailValidatorProvider>
                                    </PaymentProvider>
                                  </ButtonRefProvider>
                                </EventProvider>
                              </CampaignswellProvider>
                            </EventFilterProvider>
                          </ScrollProvider>
                        </UserDataProvider>
                      </PageConfigProvider>
                    </FirebaseConfigProvider>
                  </DevModeProvider>
                </UrlProvider>
              </DeviceProvider>
            </ErrorHandlerProvider>
          </RouteHandlerProvider>
        </GrowthBookABtestProvider>
      </EnvProvider>
    </GrowthBookProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <VersionCheckWrapper version={APP_VERSION.CURRENT}>
    <Index />
  </VersionCheckWrapper>
);
