import { USER_ACTIONS } from "./types";

export const initialState = {
  cid: null,
  email: null,
};

export const userDataReducer = (state, action) => {
  switch (action.type) {
    case USER_ACTIONS.SET_CID:
      return {
        ...state,
        cid: action.payload,
      };
    case USER_ACTIONS.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    default:
      return state;
  }
};
