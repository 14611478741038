export const useCWsegmentEvent = ({ cwSegment }) => {
  const eventData = {
    segment: cwSegment.ab_test_name,
    ab_test: cwSegment.ab_test_variant,
  };

  // console.log(`cw_segment_event`, eventData);

  window.gtag("event", "cw_segment", eventData);
};
