import React, { useCallback } from "react";
import { useDevMode } from "../../../context/DevMode";
import { useEnvContext } from "../../../context/EnvironmentContext";
import { useUrlContext } from "../../../context/urlContext";
import { useRemoteConfig } from "../../../context/RemoteConfigContext";

import styles from "./DevModeStyle.module.css";

const DevMode = () => {
  const { generateNewVariant, devModeVariant } = useDevMode();
  const { isProduction } = useEnvContext();
  const { defaultPagePath, duplicatePlanPagePathABtest } = useUrlContext();
  const { hideDevMode } = useRemoteConfig();

  const handleGenerateAndReload = useCallback(() => {
    generateNewVariant();
    setTimeout(() => window.location.reload(), 100);
  }, [generateNewVariant]);

  if (isProduction) return null;

  const shouldShowButton =
    duplicatePlanPagePathABtest || defaultPagePath || !hideDevMode;

  if (!shouldShowButton) return null;

  return (
    <button onClick={handleGenerateAndReload} className={styles.button}>
      <span className={styles.buttonText}>Switch A-B option</span>
      <div className={styles.currentDevVariant}>
        <span className={styles.currentDevVariantText}>{devModeVariant}</span>
      </div>
    </button>
  );
};

export default DevMode;
