import React, { createContext, useContext } from "react";
import { useFeatureValue, FeaturesReady } from "@growthbook/growthbook-react";
import { useConsoleLog } from "../hooks/consoleLog";

const GrowthBookContext = createContext();
export const useGrowthbook = () => useContext(GrowthBookContext);

const ABTestProvider = ({ children }) => {
  const logger = useConsoleLog();
  const abValue = useFeatureValue("variant_ab_test", null);

  if (abValue) {
    logger.abTest(`A-B value:`, abValue);
  }

  return (
    <GrowthBookContext.Provider value={{ abValue }}>
      {children}
    </GrowthBookContext.Provider>
  );
};

export const GrowthBookABtestProvider = ({ children }) => (
  <FeaturesReady timeout={1000} fallback={null}>
    <ABTestProvider>{children}</ABTestProvider>
  </FeaturesReady>
);
