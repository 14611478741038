import React from "react";
import { useEnvContext } from "../../../context/EnvironmentContext";

import ToggleComponent from "../ToggleComponent/ToggleComponent";

const EmulateProduction = () => {
  const { isProduction, isEmulatedProduction, toggleProductionEmulation } =
    useEnvContext();

  if (isProduction) return null;

  return (
    <ToggleComponent
      status={isEmulatedProduction}
      callback={toggleProductionEmulation}
    />
  );
};

export default EmulateProduction;
