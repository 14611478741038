import React from "react";

import styles from "./ButtonComponentStyle.module.css";

const ButtonComponent = ({ text, callback }) => {
  return (
    <button className={styles.button} onClick={() => callback()}>
      <span className={styles.text}>{text}</span>
    </button>
  );
};

export default ButtonComponent;
