import React, { useEffect, useState } from "react";

import { useConsoleLog } from "../../../hooks/consoleLog";

import { usePageConfig } from "../../../context/PageConfigContext";
import { usePaymentBlock } from "../../../context/PaymentBlockContext";
import { useEmailValidator } from "../../../context/EmailValidatorContext";
import { useUserData } from "../../../context/UserData/UserDataContext";

import styles from "./EmailBlockStyle.module.css";

const EmailBlock = () => {
  const logger = useConsoleLog();
  
  const { findDataComponent } = usePageConfig();

  const { rewriteEmail } = usePaymentBlock();

  const data = findDataComponent("EmailBlock");

  const userData = JSON.parse(localStorage.getItem("MemoryOSUserData"));

  const [newUserEmail, setNewUserEmail] = useState(userData.userEmail);

  const [isValidEmailFormat, setIsValidEmailFormat] = useState(true);

  const [disableBlock, setDisableBlock] = useState(false);

  const { validEmail } = useEmailValidator();

  const { setStateUserEmail } = useUserData();

  useEffect(() => {
    const handleTabKey = (e) => {
      if (disableBlock && e.key === "Tab") {
        e.preventDefault();
      }
    };

    if (disableBlock) {
      window.addEventListener("keydown", handleTabKey);
    } else {
      window.removeEventListener("keydown", handleTabKey);
    }

    return () => {
      window.removeEventListener("keydown", handleTabKey);
    };
  }, [disableBlock]);

  useEffect(() => {
    setIsValidEmailFormat(validEmail(newUserEmail));
    setDisableBlock(!validEmail(newUserEmail));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserEmail]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    const truncatedValue = value.slice(0, 50);
    setNewUserEmail(truncatedValue.toLowerCase());
    setIsValidEmailFormat(validEmail(truncatedValue));
  };

  const handleSaveButton = async () => {
    try {
      rewriteEmail(newUserEmail);
      await setStateUserEmail(newUserEmail);
      window.location.reload();
    } catch (error) {
      logger.error("Error saving email:", error);
    }
  };

  return (
    <div className={styles.emailBlockContainer}>
      <div className={styles.emailBlock}>
        <input
          className={`${styles.inputBlock} ${
            disableBlock ? styles.invalid : ""
          }`}
          type="email"
          autoComplete="email"
          placeholder={data.textPlaceholder}
          value={newUserEmail}
          onChange={handleInputChange}
          tabIndex={disableBlock ? -1 : 0}
          maxLength={50}
        />
        <button
          onClick={() => {
            handleSaveButton();
          }}
          className={`${styles.saveBtn} ${disableBlock ? styles.invalid : ""}`}
          disabled={disableBlock}
        >
          <span className={styles.buttonText}>{data.textButton}</span>
        </button>
        {disableBlock ? (
          <>
            <div className={styles.disBlockTop} />
            <div className={styles.disBlockBot} />
          </>
        ) : null}
      </div>
      {!isValidEmailFormat && (
        <span className={styles.invalidText}>{data.textInvalid}</span>
      )}
      <span className={styles.considerText}>{data.textConsider}</span>
    </div>
  );
};

export default EmailBlock;
