import React from "react";
import styles from "./ToggleComponentStyle.module.css";

const ToggleComponent = ({ status, callback }) => {
  return (
    <div className={styles.container}>
      <div
        className={`${styles.toggle} ${status ? styles.active : ""}`}
        onClick={() => callback(!status)}
        role="switch"
        aria-checked={status}
      >
        <div className={styles.handle} />
      </div>
    </div>
  );
};

export default ToggleComponent;
