import React, { useState, useRef, useEffect } from "react";
import { useEnvContext } from "../../../context/EnvironmentContext";

import EventsDevMode from "../EventsDevMode/EventsDevMode";
import DevMode from "../DevMode/DevMode";
import EmulateProduction from "../EmulateProduction/EmulateProduction";
import SetTimerTime from "../SetTimerTime/SetTimerTime";

import styles from "./DevNavBarStyle.module.css";

const DevNavBar = () => {
  const { isProduction } = useEnvContext();

  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const MenuContainer = ({ text, component: Component }) => (
    <div className={styles.menuItem}>
      <div className={styles.menuItemEvents}>
        <span className={styles.menuItemtext}>{text}</span>
        <Component />
      </div>
    </div>
  );

  if (isProduction) return null;

  return (
    <div className={styles.container} ref={popupRef}>
      <button
        className={`${styles.menuButton} ${isOpen ? styles.active : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.menuIcon}>⚙️</div>
      </button>

      {isOpen && (
        <div className={styles.popup}>
          <div className={styles.popupHeader}>Dev Tools</div>
          <div className={styles.popupContent}>
            <MenuContainer text="" component={DevMode} />
            <MenuContainer text="" component={SetTimerTime} />
            <MenuContainer text="Stage Events" component={EventsDevMode} />
            <MenuContainer text="Emulate Prod" component={EmulateProduction} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DevNavBar;
