import React from "react";
import { useUrlContext } from "../../../context/urlContext";

import styles from "./PaySafeSecureStyle.module.css";

const PaySafeSecure = ({ variant }) => {
  const { duplicatePlanPagePathABtest, defaultPagePath } = useUrlContext();

  return (
    <div className={styles.container}>
      {defaultPagePath && <div className={styles.hrLine} />}
      {duplicatePlanPagePathABtest && variant === 1 && (
        <div className={styles.hrLine} />
      )}
      <div className={styles.mainContainer}>
        <img
          src="../img/FrameReservePayment.png"
          alt="FrameReservePayment"
          className={styles.imgStyle}
        />
        <span className={styles.text}>Pay safe & secure</span>
      </div>
    </div>
  );
};

export default PaySafeSecure;
