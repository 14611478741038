import React, { useEffect, useState, useRef, useMemo } from "react";
import { usePageConfig } from "../../../context/PageConfigContext";

import { useScroll } from "../../../context/ScrollContext";
import { usePageContent } from "../../../context/PageContext";
import { useCurrentPath } from "../../../hooks/useCurrentPath";

import TopComponentVariantB from "./TopComponentVariantB/TopComponentVariantB";
import Header from "./Header/Header";
import StepsContainer from "./StepsContainer/StepsContainer";
import BottomComponent from "./BottomComponent/BottomComponent";
import FooterContainer from "../../Plan/ABspecialOfferPlan/FooterContainer/FooterContainer";

import Loader from "../../templates/Loader/Loader";

import styles from "./RedesignThankYou.module.css";

const RedesignThankYou = () => {
  const { findDataComponent } = usePageConfig();
  const { calculateRenewalDate } = usePageContent();

  const [userName, setUserName] = useState("");
  const [planData] = useState(
    JSON.parse(localStorage.getItem("MemoryOSPlanData"))
  );
  const [orderTrialStatus] = useState(
    JSON.parse(localStorage.getItem("MemoryOSCurrentOrder"))?.trial_status ||
      null
  );
  const [showHideParagraph, setShowHideParagraph] = useState(false);

  const dataComponent = findDataComponent("RedesignThankYou");
  const currentPath = useCurrentPath();

  const checkPath = useMemo(() => {
    const targetPath = "/apply-discount/thankyou";
    try {
      const url = new URL(currentPath, window.location.origin);
      const cleanPath = url.pathname;

      return cleanPath === targetPath;
    } catch (error) {
      console.error("Error parsing path:", error);
      return false;
    }
  }, [currentPath]);

  useEffect(() => {
    if (checkPath && orderTrialStatus) {
      setShowHideParagraph(checkPath);
    }
  }, [checkPath, orderTrialStatus]);

  useEffect(() => {
    const storedData = localStorage.getItem("MemoryOSUserName");
    const userNameData = JSON.parse(localStorage.getItem("MemoryOSUserData"));
    setUserName(storedData !== null ? storedData : userNameData.userName);
    calculateRenewalDate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const { scrollRefTop, scrollToTopComponent } = useScroll();
  const PageContainerRef = useRef(null);

  useEffect(() => {
    scrollRefTop.current = PageContainerRef.current;
  }, [scrollRefTop]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      scrollToTopComponent();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }, 100);
  }, [scrollToTopComponent]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.container}>
      <div ref={PageContainerRef} />
      <Header />
      <TopComponentVariantB
        userName={userName}
        dataComponent={dataComponent}
        planText={planData}
      />
      <StepsContainer
        dataComponent={dataComponent}
        showHideParagraph={showHideParagraph}
      />
      <BottomComponent dataComponent={dataComponent.bottomSection} />
      <FooterContainer />
    </div>
  );
};

export default RedesignThankYou;
