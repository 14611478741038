import React from "react";

import { useRemoteConfig } from "../../../context/RemoteConfigContext";
import { usePageContent } from "../../../context/PageContext";
import { useEnvContext } from "../../../context/EnvironmentContext";

import ButtonComponent from "../ButtonComponent/ButtonComponent";

const SetTimerTime = () => {
  const { isProduction } = useEnvContext();

  const { setTimerTime } = usePageContent();

  const { QAtimerTime } = useRemoteConfig();

  const handleResetTimer = () => {
    setTimerTime(0);
    setTimeout(() => setTimerTime(QAtimerTime), 0);
  };

  if (isProduction) return null;

  return (
    <ButtonComponent
      text={`Set timer ${QAtimerTime} min`}
      callback={handleResetTimer}
    />
  );
};

export default SetTimerTime;
