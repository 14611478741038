import React, { createContext, useContext, useReducer } from "react";
import { useEnvContext } from "./EnvironmentContext";

import {
  useCWnrcPurchase,
  useCWsegmentEvent,
  useCWsubscribe,
  useCWuserAgeEvent,
  useCWuserGenderEvent,
} from "../hooks/campaignswellAnalytics";

const CampaignswellContext = createContext();
export const useCampaignswell = () => useContext(CampaignswellContext);

const EVENT_TYPES = {
  CW_PURCHASE: {
    key: "cw_purchase",
    label: "CW Purchase Event",
    metadata: {
      description: "Track user purchase events",
      category: "revenue",
      expectedData: [
        "transaction_id",
        "client_user_id",
        "product_id",
        "product_price",
      ],
      source: "product_checkout",
    },
  },
  CW_SEGMENT: {
    key: "cw_segment",
    label: "CW Segment Event",
    metadata: {
      description: "Track user A-B test segment assignment",
      category: "segmentation",
      expectedData: ["segment", "ab_test"],
      source: "ab_testing",
    },
  },
  CW_SUBSCRIBE: {
    key: "cw_subscribe",
    label: "CW Subscribe Event",
    metadata: {
      description: "Track user subscription events",
      category: "revenue",
      expectedData: [
        "subscription_id",
        "transaction_id",
        "client_user_id",
        "product_price",
        "product_id",
      ],
      source: "subscription_checkout",
    },
  },
  CW_USER_AGE: {
    key: "cw_user_age",
    label: "CW User Age Event",
    metadata: {
      description: "Track user age information",
      category: "user_profile",
      expectedData: ["age_range"],
      source: "user_onboarding",
    },
  },
  CW_USER_GENDER: {
    key: "cw_user_gender",
    label: "CW User Gender Event",
    metadata: {
      description: "Track user gender information",
      category: "user_profile",
      expectedData: ["gender"],
      source: "user_onboarding",
    },
  },
};

const EventLogger = {
  start: (eventType, data) => {
    console.group(`🚀 ${eventType.label} Started`);
    console.log({
      event: {
        type: eventType.key,
        category: eventType.metadata.category,
        description: eventType.metadata.description,
        source: eventType.metadata.source,
      },
      timestamp: new Date().toISOString(),
      inputData: data,
      expectedFields: eventType.metadata.expectedData,
    });
    console.groupEnd();
  },

  success: (eventType, result) => {
    console.group(`✅ ${eventType.label} Completed`);
    console.log({
      event: {
        type: eventType.key,
        category: eventType.metadata.category,
        source: eventType.metadata.source,
      },
      timestamp: new Date().toISOString(),
      result: result,
      validation: {
        expectedFields: eventType.metadata.expectedData,
        receivedFields: Object.keys(result.processedData || {}),
        isComplete: eventType.metadata.expectedData.every(
          (field) =>
            result.processedData &&
            Object.keys(result.processedData).includes(field)
        ),
      },
    });
    console.groupEnd();
  },

  error: (eventType, error) => {
    console.group(`❌ ${eventType.label} Failed`);
    console.error({
      event: {
        type: eventType.key,
        category: eventType.metadata.category,
        source: eventType.metadata.source,
      },
      timestamp: new Date().toISOString(),
      error: {
        type: error.name,
        message: error.message,
        isValidationError: error.message.includes("Validation failed"),
        details: error.message.includes("Validation failed")
          ? error.message.split("Validation failed: ")[1].split(", ")
          : null,
        stack: error.stack,
      },
    });
    console.groupEnd();
  },
};
class EventValidator {
  static validateField(value, fieldName) {
    if (value === undefined || value === null || value === "") {
      throw new Error(`Field "${fieldName}" is required but got: ${value}`);
    }
    return true;
  }

  static validateEventData(data, expectedFields) {
    const errors = [];

    expectedFields.forEach((field) => {
      try {
        this.validateField(data[field], field);
      } catch (error) {
        errors.push(error.message);
      }
    });

    if (errors.length > 0) {
      throw new Error(`Validation failed: ${errors.join(", ")}`);
    }

    return true;
  }
}
class EventFactory {
  static createEvent(type, data) {
    const baseResult = {
      eventType: type.key,
      category: type.metadata.category,
      source: type.metadata.source,
      timestamp: new Date().toISOString(),
    };

    switch (type.key) {
      case EVENT_TYPES.CW_PURCHASE.key: {
        EventValidator.validateEventData(data, [
          "transaction_id",
          "client_user_id",
          "product_id",
          "product_price",
        ]);

        return {
          hook: useCWnrcPurchase,
          data: {
            cwPurchase: {
              transaction_id: data.transaction_id,
              client_user_id: data.client_user_id,
              product_id: data.product_id,
              product_price: data.product_price,
            },
          },
          formatResult: () => ({
            ...baseResult,
            processedData: {
              transaction_id: data.transaction_id,
              client_user_id: data.client_user_id,
              product_id: data.product_id,
              product_price: data.product_price,
            },
          }),
        };
      }

      case EVENT_TYPES.CW_SEGMENT.key: {
        EventValidator.validateEventData(data, ["segment", "ab_test"]);

        return {
          hook: useCWsegmentEvent,
          data: {
            cwSegment: {
              ab_test_name: data.segment,
              ab_test_variant: data.ab_test,
            },
          },
          formatResult: () => ({
            ...baseResult,
            processedData: {
              segment: data.segment,
              ab_test: data.ab_test,
            },
          }),
        };
      }
      case EVENT_TYPES.CW_SUBSCRIBE.key: {
        EventValidator.validateEventData(data, [
          "subscription_id",
          "transaction_id",
          "client_user_id",
          "product_price",
          "product_id",
        ]);

        return {
          hook: useCWsubscribe,
          data: {
            cwSubscribe: {
              subscription_id: data.subscription_id,
              transaction_id: data.transaction_id,
              client_user_id: data.client_user_id,
              product_price: data.product_price,
              product_id: data.product_id,
            },
          },
          formatResult: () => ({
            ...baseResult,
            processedData: {
              subscription_id: data.subscription_id,
              transaction_id: data.transaction_id,
              client_user_id: data.client_user_id,
              price: data.product_price,
              product_id: data.product_id,
            },
          }),
        };
      }

      case EVENT_TYPES.CW_USER_AGE.key: {
        EventValidator.validateEventData(data, ["age"]);

        return {
          hook: useCWuserAgeEvent,
          data: {
            csUserAge: {
              age_range: data.age,
            },
          },
          formatResult: () => ({
            ...baseResult,
            processedData: {
              age_range: data.age,
            },
          }),
        };
      }

      case EVENT_TYPES.CW_USER_GENDER.key: {
        EventValidator.validateEventData(data, ["gender"]);

        return {
          hook: useCWuserGenderEvent,
          data: {
            userGender: {
              gender: data.gender,
            },
          },
          formatResult: () => ({
            ...baseResult,
            processedData: {
              gender: data.gender,
            },
          }),
        };
      }

      default:
        throw new Error(`Unknown event type: ${type.key}`);
    }
  }
}

export const CampaignswellProvider = ({ children }) => {
  const { isProduction } = useEnvContext();

  const initialState = {
    events: Object.values(EVENT_TYPES).reduce(
      (acc, type) => ({
        ...acc,
        [type.key]: {
          status: null,
          result: null,
        },
      }),
      {}
    ),
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "TRIGGER_EVENT_START":
        return {
          ...state,
          events: {
            ...state.events,
            [action.eventType.key]: {
              status: "pending",
              result: null,
            },
          },
        };

      case "TRIGGER_EVENT_SUCCESS":
        return {
          ...state,
          events: {
            ...state.events,
            [action.eventType.key]: {
              status: "success",
              result: action.result,
            },
          },
        };

      case "TRIGGER_EVENT_ERROR":
        return {
          ...state,
          events: {
            ...state.events,
            [action.eventType.key]: {
              status: "error",
              result: action.error,
            },
          },
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const triggerEvent = async (eventType, eventData) => {
    if (!eventType || !eventType.key || !eventType.label) {
      console.error("Invalid event type:", eventType);
      throw new Error("Invalid event type provided");
    }

    if (!eventData) {
      console.error("No event data provided");
      throw new Error("Event data is required");
    }

    try {
      !isProduction && EventLogger.start(eventType, eventData);

      dispatch({ type: "TRIGGER_EVENT_START", eventType });

      const event = EventFactory.createEvent(eventType, eventData);
      await event.hook(event.data);

      const formattedResult = event.formatResult();

      !isProduction && EventLogger.success(eventType, formattedResult);
      dispatch({
        type: "TRIGGER_EVENT_SUCCESS",
        eventType,
        result: formattedResult,
      });

      return formattedResult;
    } catch (error) {
      EventLogger.error(eventType, error);
      dispatch({
        type: "TRIGGER_EVENT_ERROR",
        eventType,
        error,
      });
      throw error;
    }
  };

  const contextValue = {
    state,
    triggerEvent,
    EVENT_TYPES,
  };

  return (
    <CampaignswellContext.Provider value={contextValue}>
      {children}
    </CampaignswellContext.Provider>
  );
};
