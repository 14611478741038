import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useNavigationPath } from "../../../hooks/navigationPath";
import { preserveUrlParams } from "../../../utils/urlParams";

import { usePageConfig } from "../../../context/PageConfigContext";
import { usePaymentBlock } from "../../../context/PaymentBlockContext";
import { useEvent } from "../../../context/EventContext";
import { useUserData } from "../../../context/UserData/UserDataContext";

import ReactivateEmailInput from "./ReactivateEmailInput/ReactivateEmailInput";
import LogoHP from "../../templates/LogoHP/LogoHP";

import styles from "./HasActiveSubscModal.module.css";

const HasActiveSubscModal = () => {
  const { analyticsEvent, setSnaptrUserData } = useEvent();
  const { findDataComponent } = usePageConfig();

  const { setStartActiveSubscForm, loadingForm } = usePaymentBlock();

  const { setStateUserEmail } = useUserData();

  const navigate = useNavigate();
  const previosPath = useNavigationPath("plan");

  const data = findDataComponent("HasActiveSubscModal");

  const [newUserEmail, updatenewUserEmai] = useState({});
  const [validUserData, setValidUserData] = useState(false);

  const [zIndex, setZindex] = useState("9999");

  const handleSubmit = async () => {
    setZindex("9998");

    const userData = JSON.parse(localStorage.getItem("MemoryOSUserData"));

    if (userData !== null && userData.userEmail) {
      const updatedUserNoQuizData = {
        ...userData,
        userEmail: newUserEmail.userEmail,
      };
      localStorage.setItem(
        "MemoryOSUserData",
        JSON.stringify(updatedUserNoQuizData)
      );
      setSnaptrUserData(updatedUserNoQuizData);
      await setStateUserEmail(newUserEmail.userEmail);
    }

    analyticsEvent("conversion");

    const userName = JSON.parse(
      localStorage.getItem("MemoryOSUserData")
    ).userName;

    const gtagEventData = {
      ...(userName && { event_user_name: userName }),
      ...(newUserEmail.userEmail && {
        event_user_email: newUserEmail.userEmail,
      }),
    };

    if (Object.keys(gtagEventData).length > 0) {
      analyticsEvent("leadFormSubmit", gtagEventData);
    }
    setStartActiveSubscForm(false);
    loadingForm("StartPaymentForm");
    window.location.reload();
  };

  const closePopup = () => {
    navigate(preserveUrlParams(previosPath));
    return setStartActiveSubscForm(false);
  };

  return (
    <>
      <div
        className={styles.HasActiveSubscModalBackground}
        style={{ zIndex: zIndex }}
      >
        <div className={styles.HasActiveSubscModal}>
          <button className={styles.closeButton} onClick={() => closePopup()}>
            <img
              src="../img/SpecialOfferCloseImg.png"
              alt="CloseImg"
              className={styles.closeImg}
            />
          </button>
          <div className={styles.HasActiveSubscModalContainer}>
            <LogoHP />
            <div className={styles.HasActiveSubscModalText}>{data.text}</div>
            <ReactivateEmailInput
              data={data}
              updatenewUserEmai={updatenewUserEmai}
              setValidUserData={setValidUserData}
            />
            <button
              className={
                validUserData === true
                  ? styles.HasActiveSubscModalButton
                  : styles.HasActiveSubscModalButtonDisable
              }
              onClick={() => {
                if (validUserData) {
                  handleSubmit();
                }
              }}
            >
              {data.buttonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HasActiveSubscModal;
