import { createContext, useContext, useEffect, useMemo, useState } from "react";

const EnvContext = createContext();
const EMULATE_PRODUCTION_KEY = "MemoryOSemulProd";

export const useEnvContext = () => {
  const context = useContext(EnvContext);
  if (!context) {
    throw new Error("useEnvContext must be used within EnvProvider");
  }
  return context;
};

export const EnvProvider = ({ children }) => {
  const [emulateProduction, setEmulateProduction] = useState(() => {
    return sessionStorage.getItem(EMULATE_PRODUCTION_KEY) === "true";
  });

  const isRealProduction = useMemo(() => {
    const currentURL = window.location.href;
    const productionDomain = "start.memoryos.com";
    const stagingDomain = "staging.start.memoryos.com";

    return (
      currentURL.includes(productionDomain) &&
      !currentURL.includes(stagingDomain)
    );
  }, []);

  const isProduction = isRealProduction || emulateProduction;

  const toggleProductionEmulation = () => {
    const newState = !emulateProduction;
    sessionStorage.setItem(EMULATE_PRODUCTION_KEY, String(newState));
    setEmulateProduction(newState);
    window.location.reload();
  };

  useEffect(() => {
    if (isProduction) {
      console.log(
        "%cMemoryOS production" + (emulateProduction ? " (emulated)" : ""),
        "color:rgb(0, 196, 0); font-weight: bold;"
      );
    } else {
      console.log(
        "%cMemoryOS staging",
        "color:rgb(173, 113, 1); font-weight: bold;"
      );
    }
  }, [isProduction, emulateProduction]);
  

  const contextValue = {
    isProduction,
    isEmulatedProduction: emulateProduction,
    toggleProductionEmulation,
  };

  return (
    <EnvContext.Provider value={contextValue}>{children}</EnvContext.Provider>
  );
};

// import { createContext, useContext, useEffect, useMemo } from "react";

// const EnvContext = createContext();

// export const useEnvContext = () => {
//   return useContext(EnvContext);
// };

// export const EnvProvider = ({ children }) => {
//   const isProduction = useMemo(() => {
//     const currentURL = window.location.href;
//     const productionDomain = "start.memoryos.com";
//     const stagingDomain = "staging.start.memoryos.com";

//     return (
//       currentURL.includes(productionDomain) &&
//       !currentURL.includes(stagingDomain)
//     );
//   }, []);

//   useEffect(() => {
//     if (isProduction) {
//       console.log(
//         "%cMemoryOS production",
//         "color:rgb(0, 196, 0); font-weight: bold;"
//       );
//     } else {
//       console.log("%cMemoryOS staging", "color:rgb(173, 113, 1); font-weight: bold;");
//     }
//   }, [isProduction]);

//   const contextValue = {
//     isProduction,
//   };

//   return (
//     <EnvContext.Provider value={contextValue}>{children}</EnvContext.Provider>
//   );
// };
