export const useCWnrcPurchase = ({ cwPurchase }) => {
  const eventData = {
    transaction_id: cwPurchase.transaction_id,
    cw_client_user_id: cwPurchase.client_user_id,
    payment_system: "solidgate",
    price: cwPurchase.product_price,
    currency: "USD",
    placement: "payment_form",
    product_id: cwPurchase.product_id,
    quantity: 1,
  };

  // console.log(`cw_nrc_purchase event:`, eventData);

  window.gtag("event", "cw_nrc_purchase", eventData);
};
