import React, { useState } from "react";

import Payment from "@solidgate/react-sdk";

import { useRemoteConfig } from "../../../context/RemoteConfigContext";
import { useEvent } from "../../../context/EventContext";
import { usePaymentForm } from "../../../context/PaymentFormContext";

import styles from "./CardPayButtonStyle.module.css";

const CardPayButton = ({
  paypalBtnRef,
  googleContainerRef,
  appleContainerRef,
}) => {
  const { analyticsEvent } = useEvent();
  const { paymentButtons } = useRemoteConfig();
  const { merchantDataSet, startOrderStatusCheck } = usePaymentForm();

  const handleOnSuccess = (e) => {
    console.log("handleOnSuccess: ", { e });
    analyticsEvent("addPaymentInfo");

    if (paypalBtnRef.current) {
      paypalBtnRef.current.style.display = "none";
    }
    startOrderStatusCheck();
  };

  const googlePayButtonParams = {
    enabled: paymentButtons.google_pay,
    color: "black",
  };
  const applePayButtonParams = {
    enabled: paymentButtons.apple_pay,
    // integrationType: "js",
    color: "black",
    type: "plain",
  };

  const customFormStyles = {
    template: "flat",
    submit_button: {
      "background-color": "#02BEB2;",
    },
  };
  const formParams = {
    formTypeClass: "flat",
    submitButtonText: "Continue",
  };

  const [payWithCardDisplay, setPayWithCardDisplay] = useState("none");

  const payWithCard = () => {
    if (payWithCardDisplay === "flex") {
      setPayWithCardDisplay("none");
    } else if (payWithCardDisplay === "none") {
      setPayWithCardDisplay("flex");
    }
  };

  return (
    <div className={styles.cardPayContainer}>
      <button
        className={styles.payWithCardButton}
        onClick={payWithCard}
        style={{
          border: payWithCardDisplay === "none" ? "none" : "3px solid #076AFF",
        }}
      >
        <span className={styles.payWithCardText}>Pay with card</span>
        <img
          className={styles.payWithCardImg}
          src="../img/FramePayWithCardReserve.png"
          alt="FramePayWithCardReserve"
        />
        <img
          className={styles.payWithCardImg}
          src="../img/FramePayWithCardReserve2.png"
          alt="FramePayWithCardReserve2"
        />
      </button>
      <div
        className={styles.cardPayContainer}
        style={{ display: payWithCardDisplay }}
      >
        <span className={styles.cardDetailsText}>Enter your card details:</span>
        <Payment
          merchantData={merchantDataSet}
          googlePayButtonParams={googlePayButtonParams}
          applePayButtonParams={applePayButtonParams}
          googlePayContainerRef={googleContainerRef}
          applePayContainerRef={appleContainerRef}
          styles={customFormStyles}
          formParams={formParams}
          onSuccess={handleOnSuccess}
        />
      </div>
    </div>
  );
};

export default CardPayButton;
