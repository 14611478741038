import { useState, useEffect } from "react";

export const useCurrentPath = () => {
  const [currentPath, setCurrentPath] = useState(window.location.href);

  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentPath(window.location.href);
    };

    window.addEventListener("popstate", handleLocationChange);
    window.addEventListener("hashchange", handleLocationChange);

    const observer = new MutationObserver(handleLocationChange);
    observer.observe(document, { subtree: true, childList: true });

    return () => {
      window.removeEventListener("popstate", handleLocationChange);
      window.removeEventListener("hashchange", handleLocationChange);
      observer.disconnect();
    };
  }, []);

  return currentPath;
};
