import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from "react";

import { useConsoleLog } from "../../hooks/consoleLog";
import { userDataReducer, initialState } from "./reducer";
import { USER_ACTIONS } from "./types";
import { useRemoteConfig } from "../RemoteConfigContext";
import Loader from "../../pages/templates/Loader/Loader";

const UserDataContext = createContext();

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error("useUserData() must be used within UserDataProvider");
  }
  return context;
};

const CID_STORAGE_KEY = "MemoryOSUserCID";

export const UserDataProvider = ({ children }) => {
  const logger = useConsoleLog();
  const [state, dispatch] = useReducer(userDataReducer, initialState);
  const [userStateIsLoading, setIsLoading] = useState(false);
  const [isCIDInitialized, setIsCIDInitialized] = useState(false);

  const { appData, isConfigReady } = useRemoteConfig();

  logger.info("UserDataProvider render state:", {
    isConfigReady,
    isCIDInitialized,
    hasAppData: !!appData,
    apiEndpoint: appData?.REACT_APP_API_ENDPOINT,
    currentCID: state.cid,
  });

  const fetchInitialCID = useCallback(async () => {
    logger.info("Starting fetchInitialCID:", {
      apiEndpoint: appData?.REACT_APP_API_ENDPOINT,
      isConfigReady,
    });

    if (!appData?.REACT_APP_API_ENDPOINT) {
      logger.critical("API_ENDPOINT is not available");
      setIsCIDInitialized(true);
      return;
    }

    try {
      logger.info("Making API request for CID...");
      const response = await fetch(`${appData.REACT_APP_API_ENDPOINT}/cid`);
      const data = await response.json();

      if (data.cid) {
        localStorage.setItem(CID_STORAGE_KEY, data.cid);
        dispatch({ type: USER_ACTIONS.SET_CID, payload: data.cid });
        logger.info("Received new CID:", data.cid);
      } else {
        logger.warn("Received response but no CID in data");
      }
    } catch (error) {
      logger.critical("Error getting CID:", error);
    } finally {
      setIsCIDInitialized(true);
      logger.info("fetchInitialCID completed, isCIDInitialized set to true");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.REACT_APP_API_ENDPOINT]);

  const updateCIDWithEmail = useCallback(
    async (email) => {
      logger.info("Starting updateCIDWithEmail:", { email });

      if (!appData?.REACT_APP_API_ENDPOINT) {
        logger.critical("API_ENDPOINT is not available for email update");
        return;
      }

      setIsLoading(true);
      const currentCID = localStorage.getItem(CID_STORAGE_KEY) || state.cid;

      if (!currentCID) {
        logger.critical("CID is missing when trying to update email");
        setIsLoading(false);
        return;
      }

      try {
        logger.info("Making API request to check CID", { currentCID });
        const response = await fetch(
          `${appData.REACT_APP_API_ENDPOINT}/cid/check`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cid: currentCID, email }),
          }
        );

        const data = await response.json();
        logger.info("CID check response:", data);

        if (data.cid && data.cid !== currentCID) {
          localStorage.setItem(CID_STORAGE_KEY, data.cid);
          dispatch({ type: USER_ACTIONS.SET_CID, payload: data.cid });
          logger.log("CID updated after email verification:", data.cid);
        } else {
          logger.log(
            "After verification, CID was found to be relevant:",
            data.cid
          );
        }
      } catch (error) {
        logger.critical("Error updating CID from email:", error);
      } finally {
        setIsLoading(false);
        logger.info("updateCIDWithEmail completed");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appData?.REACT_APP_API_ENDPOINT, state.cid]
  );

  useEffect(() => {
    const initializeCID = async () => {
      logger.info("Starting CID initialization:", {
        isConfigReady,
        isCIDInitialized,
        hasAppData: !!appData,
      });

      if (!isConfigReady) {
        logger.info("Config not ready yet, waiting...");
        return;
      }

      logger.info("Config ready, checking localStorage");
      const storedCID = localStorage.getItem(CID_STORAGE_KEY);

      if (storedCID) {
        dispatch({ type: USER_ACTIONS.SET_CID, payload: storedCID });
        logger.info("Found CID in local storage:", storedCID);
        setIsCIDInitialized(true);
        logger.info("CID initialized from localStorage");
      } else {
        logger.info("No CID in localStorage, fetching from API");
        await fetchInitialCID();
        logger.info("fetchInitialCID completed");
      }
    };

    initializeCID();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchInitialCID, isConfigReady, appData]);

  const setStateUserEmail = useCallback(
    async (email) => {
      logger.info("Setting user email:", email);
      return new Promise((resolve) => {
        dispatch({ type: USER_ACTIONS.SET_EMAIL, payload: email });
        setTimeout(() => {
          updateCIDWithEmail(email).then(resolve);
        }, 100);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateCIDWithEmail]
  );

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      setStateUserEmail,
      userStateIsLoading,
    }),
    [state, setStateUserEmail, userStateIsLoading]
  );

  logger.info("Pre-render state check:", {
    isConfigReady,
    isCIDInitialized,
    userStateIsLoading,
    hasAppData: !!appData,
    apiEndpoint: appData?.REACT_APP_API_ENDPOINT,
  });

  if (!isConfigReady || !isCIDInitialized) {
    logger.info("Showing loader because:", {
      configNotReady: !isConfigReady,
      cidNotInitialized: !isCIDInitialized,
    });
    return <Loader />;
  }

  return (
    <UserDataContext.Provider value={contextValue}>
      {userStateIsLoading && <Loader />}
      {children}
    </UserDataContext.Provider>
  );
};
