import React from "react";

import { useEnvContext } from "../../../context/EnvironmentContext";
import { useDevMode } from "../../../context/DevMode";

import ToggleComponent from "../ToggleComponent/ToggleComponent";

const EventsDevMode = () => {
  const { isProduction } = useEnvContext();

  const { stagingEventsStatus, setStagingEventsStatus } = useDevMode();

  if (isProduction) return null;

  return (
    <ToggleComponent
      status={stagingEventsStatus}
      callback={setStagingEventsStatus}
    />
  );
};

export default EventsDevMode;
