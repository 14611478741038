import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useCallback,
  useState,
} from "react";

import { useRemoteConfig } from "./RemoteConfigContext";
import { useAnalyticsUserData } from "./AnalyticsUserData";
import { useEvent } from "./EventContext";
import { useGrowthbook } from "./GrowthBookContext";

const AmplitudeContext = createContext();

export const useAmplitude = () => useContext(AmplitudeContext);

export const AmplitudeProvider = ({ children }) => {
  const { abTestCampaignName, abTestStartCampaignDate } = useRemoteConfig();
  const { abValue } = useGrowthbook();
  const { analyticsUserData } = useAnalyticsUserData();
  const { userIdentifyEvent } = useEvent();

  const prevValuesRef = useRef({});

  const findLocalUserData = useCallback(() => {
    const userData = JSON.parse(localStorage.getItem("MemoryOSUserData")) || {};
    const quizData = JSON.parse(localStorage.getItem("MemoryOSQuizData")) || {};

    return {
      name: userData.userName || quizData.firstName || "",
      email: userData.userEmail || quizData.Email || "",
    };
  }, []);

  const [userVariant, setUserVariant] = useState("");

  useEffect(() => {
    if (!abValue) {
      setUserVariant("Fallback");
    } else {
      setUserVariant(abValue);
    }
  }, [abValue]);

  const identifyUser = useCallback(
    (key, value) => {
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value !== prevValuesRef.current[key]
      ) {
        userIdentifyEvent(key, value);
        prevValuesRef.current[key] = value;
      }
    },
    [userIdentifyEvent]
  );
  const { name, email } = findLocalUserData();

  const cleanUrl = (url) => {
    return url.split("?")[0];
  };

  const setUserPageUrl = () => {
    const existingPath = sessionStorage.getItem("MemoryOSPageUrl");

    const path = cleanUrl(window.location.href);

    if (!existingPath && path) {
      sessionStorage.setItem("MemoryOSPageUrl", path);
      return path;
    } else if (existingPath) {
      return existingPath;
    }
    return false;
  };

  useEffect(() => {
    const dataToSend = [
      { key: "user_name", value: name },
      { key: "user_email", value: email },
      { key: "user_age", value: analyticsUserData.user_age },
      { key: "user_gender", value: analyticsUserData.user_gender },
      {
        key: "user_ab_test_number",
        value: userVariant,
      },
      { key: "ab_test_name", value: abTestCampaignName },
      { key: "ab_test_start_date", value: abTestStartCampaignDate },
      { key: "page_url", value: setUserPageUrl() },
    ];

    dataToSend.forEach(({ key, value }) => identifyUser(key, value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    email,
    analyticsUserData,
    abValue,
    abTestCampaignName,
    abTestStartCampaignDate,
  ]);

  return (
    <AmplitudeContext.Provider value={{}}>{children}</AmplitudeContext.Provider>
  );
};
