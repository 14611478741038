import React from "react";
import { useAnalyticsUserData } from "../../../../context/AnalyticsUserData";
import { useCampaignswell } from "../../../../context/CampaignswellContext";

import styles from "./AgeStepStyle.module.css";

const AgeStep = ({ id, step, handleNextStep }) => {
  const { updUserData } = useAnalyticsUserData();
  const { triggerEvent, EVENT_TYPES } = useCampaignswell();

  const answerImg = [
    "../../img/quizAge1.png",
    "../../img/quizAge2.png",
    "../../img/quizAge3.png",
    "../../img/quizAge4.png",
  ];

  const handleAgeSelection = async (answer, index) => {
    try {
      updUserData("user_age", answer.text);
      handleNextStep({ id, answer, answerID: index, type: "ageStep" });

      await triggerEvent(EVENT_TYPES.CW_USER_AGE, {
        age: answer.analytics_value,
      });
    } catch (error) {
      console.error("Failed to process age selection:", error);
    }
  };

  return (
    <div className={styles.container}>
      <span className={styles.title}>{step.title}</span>
      <span className={styles.subTitle}>{step.subTitle}</span>
      <div className={styles.answerButtonsContainer}>
        {step.answers.map((answer, index) => (
          <button
            key={`${id}-${index}`}
            onClick={() => handleAgeSelection(answer, index)}
            className={styles.answerButton}
          >
            <img
              src={answer.img ? answer.img : answerImg[index]}
              alt=""
              className={styles.buttonImg}
            />
            <div className={styles.textContainer}>
              <span className={styles.answerText}>{answer.text}</span>
              <img
                src="../../img/arrowNarrowRight.png"
                alt="arrow-right"
                className={styles.arrowRight}
              />
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AgeStep;
