import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRemoteConfig } from "../../context/RemoteConfigContext";
import { usePaymentBlock } from "../../context/PaymentBlockContext";
import { usePaymentForm } from "../../context/PaymentFormContext";
import { useEnvContext } from "../../context/EnvironmentContext";
import { useDevMode } from "../../context/DevMode";
import { useUrlContext } from "../../context/urlContext";

import { useNavigationPath } from "../../hooks/navigationPath";
import { preserveUrlParams } from "../../utils/urlParams";

import PlanDetails from "./PlanDetails/PlanDetails";
import EmailBlock from "./EmailBlock/EmailBlock";
import PaymentSystems from "./PaymentSystems/PaymentSystems";
import PaySafeSecure from "./PaySafeSecure/PaySafeSecure";
import CardPayButton from "./CardPayButton/CardPayButton";
import PaymentHeader from "./PaymentHeader/PaymentHeader";
import PaymentButtons from "./PaymentButtons/PaymentButtons";
import BottomDetails from "./BottomDetails/BottomDetails";
import HasActiveSubscModal from "./HasActiveSubscModal/HasActiveSubscModal";

import Loader from "../templates/Loader/Loader";

import styles from "./PaymentFormStyle.module.css";
import PayCard from "./PayCard/PayCard";

const PaymentFormPage = () => {
  const { abTestVariant } = useRemoteConfig();
  const { devModeVariant } = useDevMode();
  const { isProduction } = useEnvContext();
  const { duplicatePlanPagePathABtest } = useUrlContext();

  const { setOrderLocalData, startActiveSubscForm } = usePaymentBlock();

  const { currentOrder, navigateObserver, prepareOrder, isLoading } =
    usePaymentForm();

  const paypalBtnRef = useRef(null);
  const appleContainerRef = useRef(null);
  const googleContainerRef = useRef(null);

  const navigate = useNavigate();
  const navigatePath = useNavigationPath("thankyou");

  const [loading, setLoading] = useState(true);

  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    const prepareOrderAsync = async () => {
      if (currentOrder !== undefined) {
        const updatedSelOption = {
          ...currentOrder,
          varOpt: abTestVariant,
        };
        setOrderLocalData(updatedSelOption);
        await prepareOrder();
        setLoading(false);
      }
    };

    prepareOrderAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (navigateObserver) {
      navigate(preserveUrlParams(navigatePath));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateObserver, navigate]);

  const commonProps = {
    paypalBtnRef,
    googleContainerRef,
    appleContainerRef,
  };

  const variant = isProduction ? abTestVariant : devModeVariant;

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles.paymentFormContainer}>
      {isLoading && <Loader />}
      {formLoading && <Loader />}
      {startActiveSubscForm && <HasActiveSubscModal />}
      <div className={styles.paymentForm}>
        <PaymentHeader />
        <PlanDetails />
        <PaymentButtons
          paypalBtnRef={paypalBtnRef}
          googleContainerRef={googleContainerRef}
          appleContainerRef={appleContainerRef}
          setFormLoading={setFormLoading}
        />

        {!duplicatePlanPagePathABtest && <CardPayButton {...commonProps} />}
        {duplicatePlanPagePathABtest &&
          (variant === 1 ? (
            <CardPayButton {...commonProps} />
          ) : (
            <PayCard {...commonProps} />
          ))}

        <PaySafeSecure variant={variant} />
        <EmailBlock />
        <PaymentSystems />
        <BottomDetails />
      </div>
    </div>
  );
};

export default PaymentFormPage;
