import React from "react";
import { Step, Column, Title, Answers, Answer } from "../../styles";
import { useAnalyticsUserData } from "../../../../context/AnalyticsUserData";
import { useCampaignswell } from "../../../../context/CampaignswellContext";

const GenderStep = ({ id, step, handleNextStep }) => {
  const { updUserData } = useAnalyticsUserData();
  const { triggerEvent, EVENT_TYPES } = useCampaignswell();

  const handleGenderSelection = async (answer, index) => {
    try {
      updUserData("user_gender", answer.gender);

      handleNextStep({ id, answer, answerID: index, type: "gender" });

      await triggerEvent(EVENT_TYPES.CW_USER_GENDER, {
        gender: answer.analytics_value,
      });
    } catch (error) {
      console.error("Failed to process age selection:", error);
    }
  };
  return (
    <Step>
      <Column>
        <Title>{step.title}</Title>
      </Column>
      <Column>
        <Answers $hideOnMobile={step.subtype && step.subtype === "rate"}>
          {step.newAnswers.map((answer, index) => (
            <Answer
              key={`${id}-${index}`}
              onClick={() => handleGenderSelection(answer, index)}
            >
              {answer.gender}
            </Answer>
          ))}
        </Answers>
      </Column>
    </Step>
  );
};

export default GenderStep;
