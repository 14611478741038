import React, { useState } from "react";

import styles from "../PaymentFormStyle.module.css";

const BottomDetails = () => {
  const [detailsData] = useState(
    JSON.parse(localStorage.getItem("MemoryOSCurrentOrder"))
  );

  return (
    <div className={styles.bottomDetailsContainer}>
      <div className={styles.textContainer}>
        {!detailsData.trial_status ? (
          <span className={styles.textWithTrial}>
            {detailsData.payment_form_text}
          </span>
        ) : (
          <span className={styles.textWithoutTrial}>
            {detailsData.trial_payment_form_text}
          </span>
        )}
      </div>
    </div>
  );
};

export default BottomDetails;
