import { useEffect, useState } from "react";

const APP_VERSION_KEY = "MemoryOSwebAppV";
const IS_VERSION_CHECK_ENABLED = true;

function compareVersions(newVersion, oldVersion) {
  const newParts = newVersion.split(".").map(Number);
  const oldParts = oldVersion.split(".").map(Number);

  for (let i = 0; i < Math.max(newParts.length, oldParts.length); i++) {
    const newPart = newParts[i] || 0;
    const oldPart = oldParts[i] || 0;

    if (newPart > oldPart) return true;
    if (newPart < oldPart) return false;
  }

  return false;
}

export function useVersionCheck(version) {
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkVersion = () => {
      try {
        if (!IS_VERSION_CHECK_ENABLED) {
          setIsChecking(false);
          return;
        }

        const storedVersion = localStorage.getItem(APP_VERSION_KEY);

        if (!storedVersion || compareVersions(version, storedVersion)) {
          localStorage.clear();
          localStorage.setItem(APP_VERSION_KEY, version);
          window.location.reload();
          return;
        }
      } catch (error) {
        console.error("Version check failed:", error);
      } finally {
        setIsChecking(false);
      }
    };

    checkVersion();
  }, [version]);

  return isChecking;
}
