export const useCWsubscribe = ({ cwSubscribe }) => {
  const eventData = {
    subscription_id: cwSubscribe.subscription_id,
    transaction_id: cwSubscribe.transaction_id,
    cw_client_user_id: cwSubscribe.client_user_id,
    payment_system: "solidgate",
    price: cwSubscribe.product_price,
    currency: "USD",
    placement: "payment_form",
    intro_offer_product_id: cwSubscribe.product_id,
    product_id: cwSubscribe.product_id,
  };

  // console.log(`cw_subscribe event`, eventData);

  window.gtag("event", "cw_subscribe", eventData);
};
